document.addEventListener('DOMContentLoaded', async function () {
  const marques = document.querySelector('.marques');
  const imgs = document.querySelectorAll('.swiper .swiper-slide');
  const img = [];
  const svgData = [];

  for (let item of imgs) {
    img.push(item.dataset.image);
  }

  // Fetch all SVGs before initializing the Swiper
  for (let url of img) {
    const response = await fetch(url);
    const data = await response.text();
    svgData.push(data);
  }

  const swiper = new Swiper('.swiper', {
    slidesPerView: '1',
    centeredSlides: true,
    effect: 'fade',
    duration: 0,
    imagesLoaded: true,
    spaceBetween: 0,
    pagination: {
      el: '.marques__pagination > div',
      clickable: true,
      renderBullet: function bullet(index, className) {
        // Use the fetched SVG data
        return '<div class="' + className + '">' + svgData[index] + '</div>';
      },
    },
    navigation: {
      nextEl: '.marques__next',
      prevEl: '.marques__prev',
    },
    on: {
      slideChange: function () {
        console.log('slideChange v5');
        const currentSlide = this.slides[this.activeIndex];
        const previousSlide = this.slides[this.previousIndex]; // Récupérer la diapositive précédente
        const tl = gsap.timeline({
          defaults: {
            duration: 0.8,
            ease: 'power1.out',
          },
        });

        // Animer l'opacité de la diapositive précédente à 0
        if (previousSlide) {
          console.log(previousSlide);
          gsap.to(previousSlide.querySelectorAll('.marques__logo, .marques__text, .marques__infos, .marques__video'), {
            opacity: 0,
            duration: 0.5,
          });
        } else {
          console.log('No previous slide');
        }

        gsap.set(marques.querySelectorAll('.marques__logo'), {
          opacity: 0,
          y: '-50px',
        });
        gsap.set(marques.querySelectorAll('.marques__text'), {
          opacity: 0,
        });
        gsap.set(marques.querySelectorAll('.marques__infos'), {
          y: '50px',
          opacity: 0,
        });

        gsap.set(marques.querySelectorAll('.marques__mask'), {
          height: '100%',
        });

        tl.to(currentSlide.querySelector('.marques__logo'), {
          y: 0,
          opacity: 1,
          delay: 0.1,
        })
          .to(
            currentSlide.querySelector('.marques__text'),
            {
              x: 0,
              opacity: 1,
            },
            '<'
          )
          .to(
            currentSlide.querySelector('.marques__infos'),
            {
              y: 0,
              opacity: 1,
            },
            '<'
          )
          .to(
            currentSlide.querySelector('.marques__video'),
            {
              opacity: 1,
            },
            '<'
          );

        tl.to(
          currentSlide.querySelector('.marques__mask'),
          {
            height: '0',
          },
          '<'
        );
      },
    },
    breakpoints: {
      576: {},
    },
  });

  window.addEventListener('resize', function () {
    swiper.update();
  });
});
